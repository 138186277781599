export const PUBLIC_ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  SET_PASSWORD: "/set-password",
  REGISTER: "/register",
  ALL: "*",
};

export const PROTECTED_ROUTES = {
  ALL: "/",
  DASHBOARD: "/dashboard",
  CATEGORY: "/category",
  CATEGORY_MANAGEMENT: "/category/category-management",
  COURSES: "/courses",
  COURSE_DETAILS: "/courses/course-details",
  COURSE_LECTURE_VIEW: "/courses/lecture-view",
  COURSE_PRACTICE_START_EXAM: "/courses/start-practice",
  COURSE_PRACTICE_EXAMINATION: "/courses/practice-exam",
  EXAM_AND_QUIZ: "/exam-quiz",
  START_EXAM: "/exam-quiz/start-exam",
  EXAMINATION: "/exam-quiz/examination",
  COMPLETED_EXAMINATION: "/exam-quiz/completed-examination",
  ASSIGNMENTS: "/assignments",
  RECOMMENDATIONS: "/recommendations",
  CALENDAR: "/calendar",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS: "/terms",
};
