import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import agent from "src/helpers/apiAgent";

import MainRoutes from "./Router";

import AuthProvider from "src/providers/Auth";
import DashboardProvider from "./providers/Dashboard";
import CourseProvider from "./providers/Course";

const theme = createTheme({
  typography: {
    fontFamily: ["Avenir-Regular"].join(","),
  },
});

// *** Setting up agent token *** //
let userData: string = localStorage.getItem("@sart_student");
if (userData !== null) {
  userData = JSON.parse(userData);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  agent.setToken(userData?.token);
}
// *** Setting up agent token *** //

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <DashboardProvider>
            <CourseProvider>
              <MainRoutes />
            </CourseProvider>
          </DashboardProvider>
        </AuthProvider>
        <Toaster position="top-center" reverseOrder={false} />
      </ThemeProvider>
    </Router>
  );
}

export default App;
