import * as React from "react";

import agent from "src/helpers/apiAgent";

import useAuth from "src/hooks/useAuth";

// Set defaults for reuse
const DEFAULTS = {
  activeCourseId: "",
  loaders: {
    courses: false,
    categories: false,
  },
  courses: [],
  categories: {},
  setActiveCourseId: (courseId: string) => {},
};
const CourseContext = React.createContext(DEFAULTS);

const CourseProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();

  const [loaders, setLoaders] = React.useState(DEFAULTS.loaders);
  const [courses, setCourses] = React.useState(DEFAULTS.courses);
  const [categories, setCategories] = React.useState(null);
  const [activeCourseId, setActiveCourseId] = React.useState(
    DEFAULTS.activeCourseId
  );

  React.useEffect(() => {
    const getCourses = async () => {
      try {
        setLoaders((prev) => ({ ...prev, courses: true })); // profile/courses
        const response = await agent.Auth.profileCourses();

        if (response.code === 200) {
          setCourses(response?.data);
          setActiveCourseId(response?.data?.[0]?.id);
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, courses: false }));
      }
    };
    if (isLoggedIn) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  React.useEffect(() => {
    const getCourseCategories = async () => {
      try {
        setLoaders((prev) => ({ ...prev, categories: true }));
        const response = await agent.Course.getCategoriesByCourseId(
          activeCourseId
        );

        if (response.code === 200) {
          setCategories((prev) => ({
            ...prev,
            [activeCourseId]: response?.data?.categories,
          }));
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, categories: false }));
      }
    };
    if (isLoggedIn && activeCourseId && !categories?.[activeCourseId]) {
      getCourseCategories();
    }
  }, [isLoggedIn, activeCourseId, categories]);

  const contextValues = {
    activeCourseId,
    courses,
    loaders,
    categories,
    setActiveCourseId,
  };

  return (
    <CourseContext.Provider value={contextValues}>
      {children}
    </CourseContext.Provider>
  );
};

export { CourseContext };
export default CourseProvider;
