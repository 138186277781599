import toast from "react-hot-toast";

const UNAUTH_STATUS = 401;

export const errorHandler = (error) => {
  try {
    if (error.status === UNAUTH_STATUS || error.code === UNAUTH_STATUS) {
      localStorage.clear();
      localStorage.removeItem("@sart_student");
      window.dispatchEvent(new Event("storage"));
      toast.error("Session expired! Logging out.", {
        id: "session",
        icon: "🔑",
      });
      return;
    }
    if (error?.response?.body) {
      error = error.response.body;
    }
    toast.error(error?.message || error);
  } catch (e) {
    toast.error("Something went wrong!");
  }
};

export const successHandler = (success) => {
  toast.success(success?.message);
};
