import { lazy, Suspense } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import { SomeUserLoginDetails } from "src/@types/User";

import { PUBLIC_ROUTES, PROTECTED_ROUTES } from "src/constants/Navigation";

import Loader from "src/components/Loader";

const RoutesWithLayout = lazy(() => import("src/components/RoutesWithLayout"));

// Public Routes
const Login = lazy(() => import("src/screens/Auth/Login"));
const SetPassword = lazy(() => import("src/screens/Auth/SetPassword"));
// Private Routes
const Dashboard = lazy(() => import("src/screens/Dashboard"));
const Course = lazy(() => import("src/screens/Course"));
const CourseDetails = lazy(() => import("src/screens/Course/CourseDetails"));
const PracticeStartExam = lazy(
  () => import("src/screens/Course/PracticeStartExam")
);
const PracticeExamination = lazy(
  () => import("src/screens/Course/PracticeExamination")
);
const LectureView = lazy(() => import("src/screens/Course/LectureView"));
const ExamAndQuiz = lazy(() => import("src/screens/ExamsAndQuiz"));
const StartExam = lazy(() => import("src/screens/ExamsAndQuiz/StartExam"));
const Examination = lazy(() => import("src/screens/ExamsAndQuiz/Examination"));
const CompletedExamination = lazy(
  () => import("src/screens/ExamsAndQuiz/CompletedExamination")
);
const Assignment = lazy(() => import("src/screens/Assignment"));
const Recommendation = lazy(() => import("src/screens/Recommendation"));
const Calendar = lazy(() => import("src/screens/Calendar"));

const PrivateRoutes = () => {
  const userData: string = localStorage.getItem("@sart_student");
  let userDataParsed: SomeUserLoginDetails;

  if (userData !== null) {
    userDataParsed = JSON.parse(userData) as SomeUserLoginDetails;
  }

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  if (token) {
    return null;
  }

  return userDataParsed?.token ? (
    <Outlet />
  ) : (
    <Navigate to={PUBLIC_ROUTES.LOGIN} />
  );
};

const MainRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loader fullscreen />}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<RoutesWithLayout />}>
            <Route path={PROTECTED_ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={PROTECTED_ROUTES.COURSES} element={<Course />} />
            <Route
              path={PROTECTED_ROUTES.COURSE_DETAILS}
              element={<CourseDetails />}
            />
            <Route
              path={PROTECTED_ROUTES.COURSE_LECTURE_VIEW}
              element={<LectureView lectures={[]} />}
            />
            <Route
              path={PROTECTED_ROUTES.COURSE_PRACTICE_START_EXAM}
              element={<PracticeStartExam />}
            />
            <Route
              path={PROTECTED_ROUTES.COURSE_PRACTICE_EXAMINATION}
              element={<PracticeExamination />}
            />
            <Route
              path={PROTECTED_ROUTES.EXAM_AND_QUIZ}
              element={<ExamAndQuiz />}
            />
            <Route path={PROTECTED_ROUTES.START_EXAM} element={<StartExam />} />
            <Route
              path={PROTECTED_ROUTES.EXAMINATION}
              element={<Examination />}
            />
            <Route
              path={PROTECTED_ROUTES.COMPLETED_EXAMINATION}
              element={<CompletedExamination />}
            />
            <Route
              path={PROTECTED_ROUTES.ASSIGNMENTS}
              element={<Assignment />}
            />
            <Route
              path={PROTECTED_ROUTES.RECOMMENDATIONS}
              element={<Recommendation />}
            />
            <Route path={PROTECTED_ROUTES.CALENDAR} element={<Calendar />} />
            <Route
              path={PROTECTED_ROUTES.ALL}
              element={<Navigate to={PROTECTED_ROUTES.DASHBOARD} />}
            />
          </Route>
        </Route>

        <Route path={PUBLIC_ROUTES.LOGIN} element={<Login />} />
        <Route path={PUBLIC_ROUTES.SET_PASSWORD} element={<SetPassword />} />
        <Route path={PROTECTED_ROUTES.PRIVACY_POLICY} element={<div />} />
        <Route path={PROTECTED_ROUTES.TERMS} element={<div />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
