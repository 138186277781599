// import BeatLoader from "react-spinners/BeatLoader";

// const Loader = () => {
//   return (
//     <BeatLoader
//       style={{
//         height: "100%",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//       size={15}
//       loading={true}
//       color={"#e74c3c"}
//     />

import classNames from "classnames";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ fullscreen = false, size = 40 }) => {
  return (
    <Box className={classNames({ "fullscreen-loader": fullscreen })}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loader;
